import { useQuery } from '@tanstack/react-query';

import { useGetFilteredAcademies } from '@templates/Explore/Explore/hooks';
import { getListAcademies } from '@templates/Explore/Explore/hooks/use-get-explore-academies/use-get-explore-academies';

export const useGetAcademyListData = ({
  lessonCode,
  academyType,
  isOnlyBookableFilter,
  latitude,
  longitude,
  isSavedListItems,
}: {
  lessonCode: LessonCode | 'TRAINING';
  academyType: 'academy' | 'simulation';
  isOnlyBookableFilter: boolean;
  latitude: number;
  longitude: number;
  isSavedListItems?: boolean;
}) => {
  const { data } = useQuery({
    queryKey: ['homeToAcademy', lessonCode, academyType, isOnlyBookableFilter, latitude, longitude],
    queryFn: async () => await getListAcademies({ lessonCode, academyType, isOnlyBookableFilter }),
    enabled: !isSavedListItems,
  });
  const result = useGetFilteredAcademies(data || [], {
    latitude,
    longitude,
  });

  return result;
};

export default useGetAcademyListData;
